function cerrarPay() {
  payIframeElement.classList.remove("showPay");
  fondoElement.classList.remove("mostrar");
  buttonCerrarPay.classList.remove("mostrar");
}

function centrarElemento(element) {
  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;
  const viewportHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const iframeWidth = element.clientWidth;
  const iframeHeight = element.clientHeight;
  const leftPosition = (viewportWidth - iframeWidth) / 2;
  const topPosition = (viewportHeight - iframeHeight) / 2;

  element.style.left = leftPosition + "px";
  element.style.top = topPosition + "px";
}

function showPayForm() {
  payIframeElement.classList.add("showPay");
  fondoElement.classList.add("mostrar");
  buttonCerrarPay.classList.add("mostrar");
  centrarElemento(payIframeElement);
}

const payIframeElement = document.querySelector("#payIframe");

const buttonsPay = document.querySelectorAll("#buttonPay");
const buttonsPayBar = document.querySelectorAll("#buttonPayBar");
const fondoElement = document.querySelector(".fondoOscuro");
const buttonCerrarPay = document.querySelector(".buttonCerrarPay");

buttonPayBar.addEventListener("click", showPayForm);

buttonsPay.forEach((buttonPay) => {
  buttonPay.addEventListener("click", showPayForm);
});

fondoElement.addEventListener("click", cerrarPay);
buttonCerrarPay.addEventListener("click", cerrarPay);

let resizeTimer;

window.addEventListener("resize", function () {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function () {
    if (payIframeElement.classList.contains("showPay")) {
      centrarElemento(payIframeElement);
    }
  }, 200);
});
