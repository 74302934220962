function girarFlechita90(flechitaElement) {
  if (flechitaElement.style.transform === "rotate(180deg)") {
    flechitaElement.style.transform = "rotate(0deg)";
  } else {
    flechitaElement.style.transform = "rotate(180deg)";
  }
}

function mostrarRespuesta(e) {
  const preguntaElement = e.target.closest(".pregunta");
  const respuestaElement = preguntaElement.querySelector(".respuesta");
  if (respuestaElement) {
    const iconoFlecha = preguntaElement.querySelector(".bi.bi-caret-down-fill");

    if (
      respuestaElement.style.height === "0px" ||
      respuestaElement.style.height === ""
    ) {
      respuestaElement.style.height = respuestaElement.scrollHeight + "px";

      respuestaElement.style.paddingTop = "10px";
      respuestaElement.style.opacity = "1";
      girarFlechita90(iconoFlecha);
    } else {
      respuestaElement.style.opacity = "0";
      respuestaElement.style.height = "0";
      respuestaElement.style.paddingTop = "0";
      girarFlechita90(iconoFlecha);
    }
  }
}

const preguntasElements = document.querySelectorAll(".pregunta");

preguntasElements.forEach((preguntaElements) => {
  preguntaElements.addEventListener("click", mostrarRespuesta);
});
