new Swiper("#swiper1", {
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },

  direction: "horizontal",
  loop: true,

  slidesPerView: 1,
  spaceBetween: 10,
  breakpoints: {
    1366: {
      slidesPerView: 3,
    },

    768: {
      slidesPerView: 2,
    },
  },
  speed: 500,
});

new Swiper("#swiper2", {
  direction: "horizontal",
  slidesPerView: "auto",
  loop: true,
  slidesPerView: 1,
  autoplay: {
    delay: 3000,
  },
  speed: 1000,
  allowTouchMove: false,
});
