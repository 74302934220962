import PhotoSwipe from "photoswipe";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

const lightbox = new PhotoSwipeLightbox({
  gallery: "#gallery",
  children: "a",
  pswpModule: PhotoSwipe,
});

lightbox.init();

const images = document.querySelectorAll(".galerry_img");

images.forEach((image) => {
  image.addEventListener("load", (e) => {
    const imgElement = e.target;
    const enlaceElementParent = imgElement.parentElement.parentElement;
    const realWidth = imgElement.naturalWidth;
    const realHeight = imgElement.naturalHeight;

    enlaceElementParent.setAttribute("data-pswp-width", realWidth);
    enlaceElementParent.setAttribute("data-pswp-height", realHeight);
  });
});
