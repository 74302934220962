const scrollLinks = document.querySelectorAll('.scroll-link');

scrollLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();
        const targetId = link.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            let offset = targetElement.offsetTop - 56; //56 px de la barra
            const windowHeight = window.innerHeight;

         
            if (targetElement.clientHeight < windowHeight) {
              
                const centerOffset = Math.max((windowHeight - targetElement.clientHeight) / 2, 0);
                offset -= centerOffset;
            }

            const scrollOptions = {
                top: offset,
                behavior: 'smooth'
            };

            window.scrollTo(scrollOptions);
        }
    });
});
