const menuElement = document.querySelector(".menu");
const showElement = document.querySelector(".button_menu");
const quitElement = document.querySelector(".menu_icon_quit");
const enlacesElements = document.querySelectorAll(".enlaces a");
const body = document.querySelector("body");
let animacionActivada = false;

function activarAnimacion() {
  menuElement.style.transition = "opacity 0.3s";
  animacionActivada = true;
}

function verificarPosicionBtn() {
  if (showElement.classList.contains("btn_down")) {
    quitElement.classList.add("btn_down");
  } else {
    quitElement.classList.remove("btn_down");
  }
}

function quitarMenu() {
  menuElement.classList.add("ocultar");
  quitElement.classList.add("ocultar");
  body.classList.remove("menu-abierto");
  verificarPosicionBtn();
}

function mostrarMenu() {
  menuElement.classList.remove("ocultar");
  quitElement.classList.remove("ocultar");
  body.classList.add("menu-abierto");
  if (!animacionActivada) {
    activarAnimacion();
  }
  verificarPosicionBtn();
}

showElement.addEventListener("click", mostrarMenu);
quitElement.addEventListener("click", quitarMenu);

enlacesElements.forEach((enlaceElement) => {
  if (window.innerWidth <= 1024) {
    enlaceElement.addEventListener("click", quitarMenu);
  }
});
