const horasElement = document.getElementById("horas");
const minutosElement = document.getElementById("minutos");
const segundosElement = document.getElementById("segundos");

const horasElementBar = document.getElementById("horas_barra");
const minutosElementBar = document.getElementById("minutos_barra");
const segundosElementBar = document.getElementById("segundos_barra");

let horas = parseInt(horasElement.textContent, 10);
let minutos = parseInt(minutosElement.textContent, 10);
let segundos = parseInt(segundosElement.textContent, 10);

function actulizarBarra() {
  horasElementBar.textContent = horasElement.textContent;
  minutosElementBar.textContent = minutosElement.textContent;
  segundosElementBar.textContent = segundosElement.textContent;
}

function actualizarContador() {
  // Actualizar el tiempo restante
  segundos--;
  if (segundos < 0) {
    segundos = 59;
    minutos--;
    if (minutos < 0) {
      minutos = 59;
      horas--;
      if (horas < 0) {
        // El contador ha llegado a cero
        clearInterval(intervaloContador);
      }
    }
  }

  horasElement.textContent = horas.toString().padStart(2, "0");
  minutosElement.textContent = minutos.toString().padStart(2, "0");
  segundosElement.textContent = segundos.toString().padStart(2, "0");
  actulizarBarra();
}

const intervaloContador = setInterval(actualizarContador, 1000);
