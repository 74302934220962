const barra = document.querySelector(".barra");
const cursoElement = document.querySelector(".contenedor_curso");
const button_menu = document.querySelector(".button_menu")

function subirBtnMenu() {
  button_menu.classList.remove("btn_down");
}

function bajarBtnMenu() {
  button_menu.classList.add("btn_down");
}

function ocultarBarra() {
  barra.classList.remove("barra-mostrar");
}

function mostrarBarra() {
  barra.classList.add("barra-mostrar");
}

function elementoEsVisible(elemento) {
  const rect = elemento.getBoundingClientRect();
  return !(
    (
      rect.bottom <= 0 || // La parte inferior está arriba del borde superior de la ventana
      rect.top >= window.innerHeight
    ) // La parte superior está debajo del borde inferior de la ventana
  );
}

function verificarBotonEnScroll() {
  if (elementoEsVisible(cursoElement)) {
    ocultarBarra();
    subirBtnMenu();
  } else {
    mostrarBarra();
    bajarBtnMenu();
  }
}

window.addEventListener("scroll", verificarBotonEnScroll);
verificarBotonEnScroll();
